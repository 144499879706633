<template>
  <div v-if="project" class="row" ref="table">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ project.title }} Packages List:</h4>

          <div class="form-group">
            <l-button class="mr-lg-1" @click="$router.go(-1)" type="danger">
               <span style="margin-right: 3px" class="btn-label">
                    <i class="fa fa-chevron-left"></i>
             </span>
              Back
            </l-button>
          </div>
        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="form-group col-md-3 col-sm-12">
            <el-select
              class="select-default"
              v-model="filters.status"
              clearable
              placeholder="Shipping Status">
              <el-option
                v-for="item in filterBuilders.shippingStatus"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="form-group col-md-3 col-sm-12">
            <l-button class="mr-lg-1" @click="getData('filterData')" type="info">Filter Result</l-button>
          </div>

        </div>
        <!--   end filters     -->

        <!--  table -->
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <el-select
            class="select-default mb-3"
            style="width: 200px"
            v-model="paginationData.pageSize"
            @change="getData('changePageSize')"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in paginationData.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <el-input type="search"
                    class="mb-3"
                    style="width: 200px"
                    placeholder="Search records"
                    v-model="searchQuery"
                    @change="getData('updateSearch')"
                    aria-controls="datatables"/>
        </div>


        <div class="col-sm-12">
          <el-table stripe
                    ref="multipleTable"
                    style="width: 100%;"
                    :data="tableData"
                    border>

            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             align="center"
                             :prop="column.prop"
                             :label="column.label"
                             :formatter="column.formatter !== null ? column.formatter : null">

              <template slot-scope="scope">

                <el-tag v-if="column.prop === 'status'"
                        :type="getStatusColor(scope.row[column.prop])"
                        size="medium">{{ scope.row[column.prop] }}
                </el-tag>

                <span v-else-if="column.prop === 'country' || column.prop === 'state' || column.prop === 'city'">
                  {{ scope.row[column.prop] != null ? scope.row[column.prop].name : "" }}
                </span>

                <span v-else-if="column.prop === 'tracking_number'">
                  <template v-if="scope.row['carrier'] === 'USPS'">
                    <a :href="getUspsLink(scope.row['tracking_number'])"
                       target="_blank">
                      {{ scope.row['tracking_number'] }}
                    </a>
                  </template>
                  <template v-else-if="scope.row['carrier'] === 'UPS'">
                    <a :href="getUpsLink(scope.row['tracking_number'])"
                       target="_blank">
                      {{ scope.row['tracking_number'] }}
                    </a>
                  </template>
                  <template v-else>{{ scope.row['tracking_number'] }}</template>
                </span>

                <span v-else>{{ scope.row[column.prop] }}</span>

              </template>

            </el-table-column>

          </el-table>
        </div>

        <div slot="footer">
          <NLPagination class="pagination-no-border"
                        v-model="paginationData.currentPage"
                        @input="getData('changePageNumber')"
                        :per-page="paginationData.pageSize"
                        :total="paginationData.totalItems">
          </NLPagination>
        </div>

        <!--  End table -->

      </card>


    </div>

  </div>
</template>
<script>
import {Table, TableColumn, Select, Option, Tag, DatePicker, Popconfirm, Popover, Button} from 'element-ui'
import {NewPagination as NLPagination} from 'src/components/index'
import DeleteAllModal from "@/components/DeleteAllModal";

export default {
  components: {
    NLPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag,
    [DatePicker.name]: DatePicker,
    [Popconfirm.name]: Popconfirm,
    [Popover.name]: Popover,
    [Button.name]: Button,
    DeleteAllModal
  },

  data() {

    return {
      loader: {},
      deleteModalVisibility: false,
      paginationData: {
        pageSize: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        totalItems: 0
      },
      searchQuery: '',
      tableColumns: [

        {
          prop: 'recipient_name',
          label: 'Recipient Name',
          minWidth: 100
        },
        {
          prop: 'recipient_company',
          label: 'Recipient Company',
          minWidth: 120
        },
        {
          prop: 'recipient_address_1',
          label: 'Address Line',
          minWidth: 100
        },
        {
          prop: "country",
          label: 'Country',
          minWidth: 100
        },
        {
          prop: "state",
          label: 'State',
          minWidth: 100
        },
        {
          prop: "city",
          label: 'City',
          minWidth: 100
        },
        {
          prop: "packages_number",
          label: 'Packages #',
          minWidth: 100
        },
        {
          prop: "tracking_number",
          label: 'Tracking #',
          minWidth: 100
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 130,
        },
        {
          prop: 'ship_date',
          label: 'Shipping Date',
          minWidth: 100
        },
      ],

      tableData: [],
      project: null,

      filterBuilders: {
        shippingStatus: []
      },

      filters: {
        status: null,
      }

    }
  },

  async created() {

    try {
      let response = await this.axios.get("projects/" + this.$route.params['uuid'] + "/packages-details-builder");
      this.filterBuilders = response.data.filters;
      this.project = response.data.project;
    } catch (e) {
      if (e.response !== undefined && e.response.status === 404) {
        this.$notify({
          message: "Project Not Found",
          timeout: 2000,
          type: 'danger'
        });
      }
    }

    if (this.project) {
      this.getData();
    }

  },

  methods: {

    getData(action) {
      switch (action) {
        case 'filterData':
          this.updateCurrentPage(1);
          break;
        case 'updateSearch':
          this.updateCurrentPage(1);
          break;
        case 'changePageSize':
          this.updateCurrentPage(1);
          break;
        case 'changePageNumber' :
          break;
      }

      this.loader = this.$loading.show({
        container: this.$refs.table
      });

      this.axios.post("projects/" + this.$route.params['uuid'] + "/packages-details" +
        "?page=" + this.paginationData.currentPage + "&pageSize=" + this.paginationData.pageSize,
        {searchQuery: this.searchQuery, filters: this.filters}).then((response) => {
        this.tableData = response.data.data;
        this.paginationData.totalItems = response.data.pagination.total;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      });
    },

    updateCurrentPage(pageNumber) {
      this.paginationData.currentPage = pageNumber;
    },

    getStatusColor(status) {
      let classes = {
        "Label Created": "info",
        "In Transit": "",
        "Delivered": "success",
        "Pending Recipient Action": "warning",
        "Undeliverable": "danger",
      }
      return classes[status];
    },

    getUspsLink(trackingNumber) {
      let link = "https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1={tn}";
      link=link.replace("{tn}", trackingNumber);
      return link;
    },

    getUpsLink(trackingNumber) {
      let link = "https://wwwapps.ups.com/WebTracking/processInputRequest?AgreeToTermsAndConditions=yes&loc=en_US&tracknum={tn}&requester=ST/";
      link=link.replace("{tn}", trackingNumber);
      return link;
    },
  },


}
</script>
<style>
</style>
